/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind base;
@tailwind components;
@tailwind utilities;

input{
    font-size: 15px;
    font-family: "Montserrat";
}

.app.blue .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label{
    transform : var( --mat-mdc-form-field-label-transform, translateY(-50%) translateX(calc(1 * (50px + var(--mat-mdc-form-field-label-offset-x, 0px)))) )!important;
}

/*
todo custom css
*/
#app > app-pages > mat-sidenav-container > mat-sidenav-content > main > app-index > app-moteur-recherche > div > div > form > div:nth-child(4) > a{
    cursor: pointer;
}
//.cursor-pointer {
//    cursor: pointer;
//}

#mat-mdc-dialog-0 > div > div > app-dialog-marker > button{
    z-index: 999;
}

.text-word-wrap{
    word-wrap: break-word;
    width:100%;
}

//.opening-hours {
//    background-color: #fff;
//    max-width: 300px;
//    margin: 20px auto;
//    padding: 20px;
//    border: 1px solid #ccc;
//    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//    text-align: center;
//}
//
//.schedule {
//    list-style-type: none;
//    padding: 0;
//    margin: 0;
//}
//
//.schedule li {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    margin: 10px 0;
//}
//
//.schedule span {
//    font-weight: bold;
//}
//
//.schedule span:last-child {
//    color: #888;
//}

.opening-hours {
    background-color: #fff;
    max-width: 300px;
    margin: 5px auto;
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.day {
    padding: 5px 10px;
    text-align: left;
}

.hour {
    padding: 5px 10px;
    font-weight: bold;
}

.closed {
    color: #888;
}

.bg-disabled{
    background-color:  #eeeeee;
    color:  #ccc;
}