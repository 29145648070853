.iti input {
    display: block !important;
    margin-bottom: 20px;
    height: 48px !important;
    margin-top: 2px !important;
    min-width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}
.iti  {
    min-width: 100%;
}

.iti--allow-dropdown input[type=tel]{
    padding-left: 60px;
}

.style-tel-input, .iti input {
  height: 56px !important;
}

.iti__selected-flag {
    padding: 0 14px 0 14px;
}

  
  .code-validation-success {
    background-color: #00c853 !important;
    color: #fff;
  }
  
  .code-validation-error {
    background-color: #f44336 !important;
  }
  
  .code-validation-filled {
    background-color: #e8f5e9;
  }
  
