$spinner-color: #1976d2;
.spinner-wrapper{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1; 
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;

    .loader_logo{
        height: 100%;
        .loading_logo{
            width: 80px;
            height: 100%;
            z-index: 1;
            margin: auto;
        }
    }



    &.hide{
        visibility: hidden;
        opacity: 0; 
    }
    .spinner-container{
        height:100%;
        width:100%;
        display:table;
        .spinner-outer{
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .spinner{
                position: relative;
                margin: 0 auto;
                height: 50px;
                width: 70px;
                box-sizing: border-box; 
                animation: main 2s ease-in infinite;
                .mask{
                    box-sizing: border-box;
                    overflow: hidden;
                    position: absolute; 
                    border-radius: 3px;
                }
                .plane{
                    background: $spinner-color;
                    width:100%;
                    height:100%;
                    position:absolute; 
                }
                .left{
                    width: 18px;
                    height: 100%;
                    left: 0;        
                    .plane{
                        bottom: -100%;
                        animation : anim1 2s ease-in infinite;
                    }
                }
                .top{
                    width: 100%;
                    height: 18px;
                    top: 0;
                    border-radius: 1px;
                    .plane{  
                        left: -100%;          
                        animation : anim2 2s ease-in infinite;
                    }
                }
                .right{
                    width: 18px;
                    height: 100%;
                    right: 0;        
                    .plane{ 
                        top: -100%;
                        animation : anim3 2s ease-in infinite;
                    }
                }            
                .triangle{
                    box-sizing: border-box; 
                    overflow: hidden;
                    position: absolute; 
                    width: 50px;
                    height: 50px;
                    top: -25px; 
                    left: 10px;
                    transform: rotate(-45deg);
                    .triangle-plane{
                        border: 50px solid transparent;
                        border-right: 0;
                        border-top-color: $spinner-color;
                        height: 0;
                        width: 0;
                        position: absolute;
                        animation : anim4 2s ease-in infinite;
                    } 
                }         
                .top-left{
                    width: 100%;
                    height: 9px;
                    transform: rotate(135deg);
                    left: -22px;
                    top: -28px;
                    .plane{
                        left: 100%;
                        animation : anim5 2s ease-in infinite;
                    }
                }
                .top-right{
                    width: 100%;
                    height: 9px;
                    transform: rotate(45deg);
                    right: -22px;
                    top: -28px;
                    .plane{
                        right: 100%;
                        animation : anim6 2s ease-in infinite;
                    }
                }                
            }
            p.spinner-text{  
                font-family: 'Montserrat';
                font-size: 16px; 
                font-weight: 500;
                text-align: center;
            }
        }
    }
}


@keyframes main{ 
    0% {
        opacity: 0;
    }
    10%, 80% {
        opacity: 1;
    } 
    100% {
        opacity: 0;
    }
}

@keyframes anim1{ 
    0% {
        bottom: -100%;
    }    
    10%, 100%{
        bottom: 0%;
    } 
}
@keyframes anim2{ 
    0%, 10%{
        left: -100%;
    }    
    20%, 100%{
        left: 0%;
    }  
}
@keyframes anim3{ 
    0%, 20%{
        top: -100%;
    }    
    30%, 100%{
        top: 0%;
    } 
}
@keyframes anim4{ 
    0%, 30%{
        top: -100%;
    }    
    40%, 100%{
        top: 0%;
    } 
}
@keyframes anim5{ 
    0%, 40%{
        left: 100%;
    }    
    50%, 100%{
        left: 0%;
    } 
}
@keyframes anim6{ 
    0%, 50%{
        right: 100%;
    }    
    60%, 100%{
        right: 0%;
    } 
}
