/*
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}*/
