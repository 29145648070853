/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
@import "app/theme/styles/custom";
@import "app/theme/styles/heromap";

.green-snackbar div {    
    border-radius: 5px;
    background: rgb(33, 100, 58) !important;
    color: white;
}
.green-snackbar button {
    background-color: rgb(33, 100, 58);
    color: white;
    border: none;
}
.red-snackbar div {
    border-radius: 5px;
    background: #c22a1f !important;
    color: white;
}
.red-snackbar button {
    background-color: #c22a1f;
    color: white !important;
    border: none;
}

.black-snackbar div {
    border-radius: 5px;
    color: white;
}
.black-snackbar button {
    color: white !important;
    border: none;
}

/*Alert messages design*/
.alert-message-style-info{
    border-radius: 10px;
    border: 1.5px solid #6FCBDB;
}
.alert-message-style-warning{
    border-radius: 10px;
    border: 1.5px solid #FACA15;
}
.alert-message-style-success{
    border-radius: 10px;
    border: 1.5px solid #84E1BC;
}
.alert-message-style-danger{
    border-radius: 10px;
    border: 1.5px solid #F8B4B4;
}

/*@media screen and (max-width: 540px) {
    #cdk-overlay-0{
        width: 200px !important;
        left: 192px !important;
    }
    .motif-style{
        width: 2.5rem;
    }
    .label-planning{
        display: block !important;
    }
    .label-planning-localisation{
        display: block !important;
    }
}*/
@media screen and (max-width: 1190px) {
    .ProfileCanopy-navBar{
      display: none;
    }
}

.label-planning{
    display: none;
}
.label-planning-localisation{
    display: none;
}

.hero-map-dialog {
    max-width: 100% !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: #11b3a4 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
    color: #11b3a4 !important;
    background-color: #11b3a4 !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: #11b3a4 !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    opacity: 0.5 !important;     /*click effect color change*/
    background-color: blue !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: blue!important;   /*inner circle color change*/
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color:blue!important; /*outer ring color change*/
}
#phone{
    border: 1px solid #9E9E9E;
}
#phone:hover{
    border: 1px solid #000;
}
#phone.error {
    border: 1px solid red; /* Error border */
}

mat-error{
    font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
    font-size: var(--mdc-typography-caption-font-size, 12px);
    line-height: var(--mdc-typography-caption-line-height, 20px);
    font-weight: var(--mdc-typography-caption-font-weight, 400);
    letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
    -webkit-text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
    text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
    text-transform: var(--mdc-typography-caption-text-transform, none);
    margin-bottom: 0 !important;
}